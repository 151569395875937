import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const AppnexusBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["appnexus"],
    },
    params: {
      type: "object",
      properties: {
        placementId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
      },
      required: ["placementId"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
