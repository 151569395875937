import { BaseJsonSchema, createSchemaByBasicTypes } from "../../helpers";
import { IGoogleAdTagDefaults } from "../googleAdTagDefaults";

export const IGAM: BaseJsonSchema = {
  type: "object",
  properties: {
    gamChildNetworkCode: createSchemaByBasicTypes("string"),
    googleAdTagDefaults: IGoogleAdTagDefaults,
  },
  additionalProperties: false,
};
