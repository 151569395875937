import React, { useState, useEffect, useCallback } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import { useQueryClient } from "react-query";
import useVideos, { Query } from "../../hooks/useVideos";
import SearchInput from "../../components/SearchInput";
import CategoriesSelect from "../../components/Select/CategoriesSelect";
import { DatePicker, DateRange } from "../../components/DatePicker/DatePicker";
import VideosTable from "../../components/VideosTable";
import useSnackbar from "../../hooks/useSnackbar";
import TagSelect from "../../components/TagSelect/TagSelect";
import { AutocompleteProps, SnackbarState } from "../../@types/media.types";
import { Video } from "../../api/types";
import { isEnableEditDelete } from "../../helpers/videos";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import VideoSnackbar from "../../components/Snackbar/ConfirmationSnackbar";
import useAPI from "../../api/useAPI";
import MediaLoader from "./MediaLoader";
import { tier1Categories } from "../../constants/iab-t1-categories-v3";
import useTags from "../../hooks/useTags";
import { useURLParams } from "../../hooks/useUrlParams";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
  };
  selectedVideos: Video[];
  onChangeSelected: (selectedVideosToken: Video[]) => void;
  setIsBulkDeleteOpen: (isOpen: boolean) => void;
  isBulkDeleteOpen: boolean;
  query: Query;
  setQuery: (query: Query) => void;
};

export default function VideoLibrary({
  asAdmin,
  selectedVideos,
  onChangeSelected,
  isBulkDeleteOpen,
  setIsBulkDeleteOpen,
  query,
  setQuery,
  publisherSlug,
}: Props) {
  const [search, setSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<
    AutocompleteProps[]
  >([]);
  const [selectedTags, setSelectedTags] = useState<AutocompleteProps[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const { snackbarWarning } = useSnackbar();
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    type: TYPE_ENUM.success,
    isOpen: false,
    message: "",
  });
  const { videos, query: videosQuery, totalCount } = useVideos(query);

  const { tags: initialTags } = useTags({
    ...(publisherSlug && { slug: publisherSlug }),
  });
  const {
    debouncedUpdateSearch,
    updateAutocompleteParam,
    updateDateRangeParams,
    getParamAsArray,
    getParamAsString,
    getParamAsDateRange,
  } = useURLParams();

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };
  const handleConfirmBulkDelete = useCallback(async () => {
    try {
      await api.Media.bulkRemove({
        tokens: selectedVideos.map((video) => video.token),
        group: publisherSlug,
      });
      queryClient.invalidateQueries(["videos", query]);
      setSnackbarState({
        type: TYPE_ENUM.success,
        isOpen: true,
        message: `Successfully deleted video(s)`,
      });
      onChangeSelected([]);
    } catch (error) {
      setSnackbarState({
        type: TYPE_ENUM.error,
        isOpen: true,
        message: `Failed to deleted videos`,
      });
    } finally {
      setIsBulkDeleteOpen(false);
    }
  }, [isBulkDeleteOpen, api.Media, queryClient, query]);

  const handleToggleAll = (checked: boolean) => {
    if (!checked) {
      onChangeSelected([]);
      return;
    }
    if (videos.length === 0) {
      snackbarWarning("The Video Library is empty.");
      return;
    }
    const tempSelectedVideos = videos.filter((video: Video) =>
      isEnableEditDelete(video.state),
    );
    if (tempSelectedVideos.length === 0) {
      snackbarWarning("No videos with an active or draft state.");
      return;
    }
    onChangeSelected(tempSelectedVideos);
  };

  // Handle toggling selection for a single video
  const handleVideoToggle = (selectedVideo: Video, isSelected: boolean) => {
    if (isSelected) {
      onChangeSelected([...selectedVideos, selectedVideo]);
    } else {
      onChangeSelected(
        selectedVideos.filter((video) => video.token !== selectedVideo.token),
      );
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    debouncedUpdateSearch(value);
  };

  const handleCategories = (categories: AutocompleteProps[]) => {
    setSelectedCategories(categories);
    updateAutocompleteParam("categories", categories);
  };

  const handleTags = (tags: AutocompleteProps[]) => {
    setSelectedTags(tags);
    updateAutocompleteParam("tags", tags, "name");
  };

  const handleDateChange = (range: DateRange | undefined) => {
    setDateRange(range);
    updateDateRangeParams(range);
  };

  useEffect(() => {
    const searchParam = getParamAsString("search");
    const categoryIds = getParamAsArray("categories");
    const range = getParamAsDateRange();

    setSearch(searchParam);
    setSelectedCategories(
      tier1Categories.filter((category) =>
        categoryIds.includes(String(category.id)),
      ),
    );
    setDateRange(range);
  }, []);

  useEffect(() => {
    const tagNames = getParamAsArray("tags");
    setSelectedTags(
      initialTags
        .map((tag, id) => ({ id, name: tag }))
        .filter((tag) => tagNames.includes(String(tag.name))),
    );
  }, [initialTags]);

  useEffect(() => {
    const newQuery = {
      ...(search.length >= 3 && { text: search }),
      ...(selectedCategories.length > 0 && {
        category: selectedCategories.map((category) =>
          category.name.toLowerCase(),
        ),
      }),
      ...(selectedTags.length > 0 && {
        tags: selectedTags.map((tag) => tag.name.toLowerCase()),
      }),
      ...(publisherSlug && { slug: publisherSlug }),
      ...(dateRange &&
        dateRange.to &&
        dateRange.from && { date_range: dateRange }),
    };
    setQuery(newQuery);
  }, [search, selectedCategories, selectedTags, dateRange, publisherSlug]);

  return (
    <Box>
      <Grid
        item
        container
        xs={12}
        md={12}
        spacing={2}
        justifyContent="flex-start"
      >
        <Grid item xs={12} md="auto">
          <SearchInput
            placeholder="Search"
            text={search}
            onTextChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <CategoriesSelect
            value={selectedCategories}
            onChange={handleCategories}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <TagSelect
            asAdmin={asAdmin}
            value={selectedTags}
            onChange={handleTags}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <DatePicker onDateRangeChange={handleDateChange} value={dateRange} />
        </Grid>
      </Grid>
      <Divider
        variant="fullWidth"
        sx={{
          mt: "21px",
          borderColor: "transparent",
        }}
      />
      {videosQuery.isFetched ? (
        <VideosTable
          videos={videos}
          selectedVideosToken={selectedVideos.map((video) => video.token)}
          onToggleAll={handleToggleAll}
          onVideoToggle={handleVideoToggle}
          query={query}
          totalCount={totalCount}
        />
      ) : (
        <MediaLoader />
      )}
      {/* Bulk Delete Confirm Modal */}
      <ConfirmModal
        isOpen={isBulkDeleteOpen}
        onClose={() => setIsBulkDeleteOpen(false)}
        onSubmitClick={handleConfirmBulkDelete}
        header="Delete Video(s)"
        body="Deleting these video(s) will permanently remove them from your library and any existing playlists. Are you sure you want to proceed?"
        submitButtonText="Delete"
        cancelButtonText="Cancel"
      />
      <VideoSnackbar
        type={snackbarState.type}
        isOpen={snackbarState.isOpen}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
      />
    </Box>
  );
}
