import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const MedianetBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["medianet"],
    },
    params: {
      type: "object",
      properties: {
        cid: createSchemaByBasicTypes("string", null, {
          additionalProperties: false,
        }),
        crid: createSchemaByBasicTypes("string", null, {
          additionalProperties: false,
        }),
        bidfloor: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
      },
      required: ["cid", "crid"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
