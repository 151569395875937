import React from "react";
import { Delete, Edit, Restore } from "@mui/icons-material";
import { Video } from "../../api/types";
import { ActionType, MediaState } from "../../@types/media.types";
import { isEnableEditDelete } from "../../helpers/videos";

interface VideosTableProps {
  video: Video;
  onActionClick?: (type: ActionType, video: Video) => void;
  readonly onEditActionClick?: (token: string) => void;
}

const iconStyle = {
  ml: "10px",
  "&:hover": {
    cursor: "pointer",
    color: "#03f",
  },
};
const disabledStyle = {
  color: "rgba(0, 0, 0, 0.26)",
  cursor: "not-allowed",
  pointerEvents: "none",
  ...iconStyle,
};

export default function VideoActions({
  video,
  onActionClick,
  onEditActionClick,
}: VideosTableProps) {
  const state = video?.state?.toLowerCase();

  return (
    <>
      <Edit
        sx={isEnableEditDelete(state) ? { ...iconStyle, ml: "0px" } : { ...disabledStyle, ml: "0px" }}
        onClick={() => onEditActionClick(video?.token)}
      />
      {state !== MediaState.SOFT_DELETED &&
        state !== MediaState.HARD_DELETED && (
          <Delete
            sx={isEnableEditDelete(state) ? iconStyle : disabledStyle}
            onClick={() => onActionClick("delete", video)}
          />
        )}
      {state === MediaState.SOFT_DELETED && (
        <Restore
          sx={iconStyle}
          onClick={() => onActionClick("restore", video)}
        />
      )}
    </>
  );
}
