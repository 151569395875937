// hooks/useSortingWithURL.ts
import { useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  TableOrder,
  TableOrderBy,
} from "@kargo/shared-components.krg-data-table";

type DataTableHeaderRef = {
  setOrder: (tableOrder: TableOrder, tableOrderBy: TableOrderBy) => void;
};

export function useSortingWithURL() {
  const headerRef = useRef<DataTableHeaderRef>(null);
  const location = useLocation();
  const history = useHistory();

  // Handle sorting changes and update URL
  const handleSortChange = useCallback(
    (order: TableOrder, field: TableOrderBy) => {
      const searchParams = new URLSearchParams(location.search);

      if (order && field) {
        searchParams.set("sortOrder", order.toString());
        searchParams.set("sortField", field as string);
      } else {
        searchParams.delete("sortOrder");
        searchParams.delete("sortField");
      }

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location],
  );

  // Load sorting from URL on mount and when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sortOrder = params.get("sortOrder") as TableOrder;
    const sortField = params.get("sortField");

    if (sortOrder && sortField && headerRef.current) {
      headerRef.current.setOrder(sortOrder, sortField);
    }
  }, [location.search]);

  return {
    headerRef,
    handleSortChange,
  };
}
