import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const RubiconBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["rubicon"],
    },
    params: {
      type: "object",
      properties: {
        accountId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
        siteId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
        zoneId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
      },
      required: ["accountId", "siteId", "zoneId"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
