import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const SharethroughBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["sharethrough"],
    },
    params: {
      type: "object",
      properties: {
        pkey: createSchemaByBasicTypes("string", null, {
          additionalProperties: false,
        }),
      },
      required: ["pkey"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
