import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const TripleliftBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["triplelift"],
    },
    params: {
      type: "object",
      properties: {
        inventoryCode: createSchemaByBasicTypes("string", null, {
          additionalProperties: false,
        }),
        floor: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
      },
      required: ["inventoryCode"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
