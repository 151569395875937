import { useCallback } from "react";
import { DateRange } from "@kargo/shared-components.krg-date-picker";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  PlaylistInput,
  PlaylistQuery,
  PlaylistFilter,
  PlaylistTagInput,
  PlaylistCategoryInput,
} from "../@types/playlist.types";
import useAPI from "../api/useAPI";
import useSnackbar from "./useSnackbar";
import { PLAYLIST_FETCH_LIMIT } from "../constants/constants";

const getQueryParams = ({
  title,
  page = 0,
  limit = PLAYLIST_FETCH_LIMIT,
  skip,
  type = null,
  sort = { created_at: "desc" },
  date_range,
  slug,
}: PlaylistFilter): PlaylistQuery => {
  const query: PlaylistQuery = {
    skip: skip || limit * page,
    limit,
    sort: typeof sort !== "string" ? JSON.stringify(sort) : sort,
    ...(type && { type }),
    ...(title && { title }),
    ...(slug && { slug }),
  };
  if (date_range) {
    const { from, to } = date_range as DateRange;
    query.created_range = JSON.stringify({
      start: from ? from.getTime() : undefined,
      end: to ? new Date(to).setDate(new Date(to).getDate() + 1) : undefined,
    });
  }
  return query;
};

export default function usePlaylists(filter: PlaylistFilter) {
  const queryClient = useQueryClient();
  const { snackbarSuccess } = useSnackbar();
  const { api, didLoad } = useAPI();
  const fetchPlaylists = useCallback(
    () => api && api.Playlist.list(getQueryParams(filter)),
    [api, filter],
  );
  const query = useQuery(["playlists", filter], () => fetchPlaylists(), {
    enabled: didLoad,
    staleTime: Infinity,
  });
  const createMutation = useMutation({
    mutationFn: (input: PlaylistInput) => api.Playlist.create(input),
    onSuccess: () => {
      snackbarSuccess("Playlist Successfully Created");
      queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
  const createByTagMutation = useMutation({
    mutationFn: (input: PlaylistTagInput) => api.Playlist.createByTag(input),
    onSuccess: () => {
      snackbarSuccess("Playlist Successfully Created");
      queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
  const createByCategoryMutation = useMutation({
    mutationFn: (input: PlaylistCategoryInput) =>
      api.Playlist.createByCategory(input),
    onSuccess: () => {
      snackbarSuccess("Playlist Successfully Created");
      queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: (token: string) => api.Playlist.delete(token),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
  const updateMutation = useMutation({
    mutationFn: ({ token, data }: { token: string; data: PlaylistInput }) =>
      api.Playlist.update(token, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["playlists"] });
    },
  });
  return {
    query,
    playlists: query.data?.playlists || [],
    api: {
      create: createMutation.mutate,
      createByTag: createByTagMutation.mutate,
      createByCategory: createByCategoryMutation.mutate,
      delete: deleteMutation.mutate,
      update: updateMutation.mutate,
    },
    mutations: {
      createMutation,
      createByCategoryMutation,
      createByTagMutation,
      deleteMutation,
      updateMutation,
    },
  };
}
