import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const CriteoBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["criteo"],
    },
    params: {
      type: "object",
      properties: {
        networkId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
        pubid: createSchemaByBasicTypes("string", null, {
          additionalProperties: false,
        }),
        zoneId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
      },
      required: ["networkId"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
