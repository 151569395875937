import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import useVideo from "../../../hooks/useVideo";
import { PATHS } from "../../../constants/paths";
import LoadingCircle from "../../../components/LoadingCircle";
import { SingleMediaURLParams } from "../../../@types/url.types";
import SingleMediaPageContainer from "./SingleMediaPageContainer";
import { FormProvider } from "../../../context/form/form.provider";
import useSinglePageSidebar from "../../../hooks/useSinglePageSidebar";
import { SidebarListItemButtonProps } from "../../../@types/sidebar.types";
import SidebarBackIconItem from "../../../components/Sidebar/SidebarBackIconItem";
import { FormInitInputs } from "../../../@types/form.types";
import { tier1Categories as categories } from "../../../constants/iab-t1-categories-v3";
import { handleBack } from "../../../helpers/utils";

const SingleMediaPage = () => {
  const history = useHistory();
  // Show single page sidebar
  const sidebarItem: SidebarListItemButtonProps = {
    selected: false,
    text: "Media Gallery",
    onClick: () => handleBack(history, PATHS.MEDIA_GALLERY),
    iconElement: <SidebarBackIconItem />,
  };
  useSinglePageSidebar([sidebarItem]);

  const { token } = useParams<SingleMediaURLParams>();
  if (!token) throw new Error("No ID found in media data");

  const { video } = useVideo(token);

  if (!video)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <LoadingCircle />
      </Box>
    );

  const {
    title,
    description,
    category,
    tags,
    media_info: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      original_file_name,
    },
  } = video;

  const formInitialState: FormInitInputs = {
    title: {
      name: "title",
      label: "Video Title",
      type: "text",
      required: true,
      rules: { length: { min: 5 } },
      // errorMessage: "Video Title is required and should me min 5 characters length",
      value: title || original_file_name,
      popoverText: "Title of the video",
      hinterText: "*required",
    },
    description: {
      name: "description",
      label: "Video Description",
      type: "text",
      required: true,
      rules: { length: { min: 20 } },
      errorMessage:
        "Video Description is required and has to be min 20 characters length",
      value: description,
      popoverText: "Please add more information about your video",
      hinterText: "*required",
    },
    tags: {
      name: "tags",
      label: "Tags",
      type: "chip",
      value: tags?.map((tag: string) => ({ id: tag, text: tag })) || [],
      popoverText: "Commas or semicolon will separate the tags",
    },
    category: {
      name: "category",
      label: "Category",
      type: "select",
      required: true,
      value: category?.split(",")
        ? category.split(",").map((val: string) => val.trim().toLowerCase())
        : "",
      errorMessage: "Only one category selection allowed",
      options:
        categories.map(({ name }) => ({
          value: name.toLowerCase(),
          text: name,
        })) || [],
      popoverText: "Please select a category for your video",
    },
  };

  return (
    <FormProvider formInputFields={formInitialState}>
      <SingleMediaPageContainer
        initialFormState={formInitialState}
        video={video}
        token={token}
      />
    </FormProvider>
  );
};

export default SingleMediaPage;
