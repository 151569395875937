import { BaseJsonSchema, createSchemaByBasicTypes } from "../../../helpers";

export const SmartAdServerBidSchema: BaseJsonSchema = {
  type: "object",
  properties: {
    bidder: {
      type: "string",
      enum: ["smartadserver"],
    },
    params: {
      type: "object",
      properties: {
        networkId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
        siteId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
        pageId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
        formatId: createSchemaByBasicTypes("number", null, {
          additionalProperties: false,
        }),
      },
      required: ["networkId", "siteId", "pageId", "formatId"],
    },
  },
  additionalProperties: false,
  required: ["bidder", "params"],
};
