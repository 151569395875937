import { ReactNode } from 'react';

export interface ToggleButtonItem {
  id?: string;
  label?: string;
  value: string | number | null;
  icon?: ReactNode;
  isStartIcon?: boolean;
  isEnabled?: boolean;
}
