// useURLParams.ts
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "lodash";

export interface DateRange {
  from: Date;
  to?: Date;
}

export interface AutocompleteProps {
  id: string | number;
  name: string;
}

export function useURLParams() {
  const history = useHistory();
  const location = useLocation();

  // Base function to update any URL param
  const updateParam = useCallback(
    (paramName: string, value: string | string[] | null) => {
      const searchParams = new URLSearchParams(location.search);
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        searchParams.delete(paramName);
      } else if (Array.isArray(value)) {
        searchParams.set(paramName, value.join(","));
      } else {
        searchParams.set(paramName, value);
      }

      history.push({ search: searchParams.toString() });
    },
    [history, location.search],
  );

  // Debounced search param update
  const debouncedUpdateSearch = useCallback(
    debounce((search: string) => {
      updateParam("search", search || null);
    }, 300),
    [updateParam],
  );

  // Handle autocomplete selections (categories, tags, types, etc.)
  const updateAutocompleteParam = useCallback(
    (
      paramName: string,
      items: AutocompleteProps[],
      propName: keyof AutocompleteProps = "id",
    ) => {
      if (items.length > 0) {
        updateParam(
          paramName,
          items.map((item) => String(item[propName])),
        );
      } else {
        updateParam(paramName, null);
      }
    },
    [updateParam],
  );

  // Handle date range
  const updateDateRangeParams = useCallback(
    (range: DateRange | undefined) => {
      const searchParams = new URLSearchParams(location.search);

      if (range?.from) {
        searchParams.set("fromDate", range.from.toISOString());
      } else {
        searchParams.delete("fromDate");
      }

      if (range?.to) {
        searchParams.set("toDate", range.to.toISOString());
      } else {
        searchParams.delete("toDate");
      }

      history.push({ search: searchParams.toString() });
    },
    [history, location.search],
  );

  // Parse params from URL
  const getParamAsArray = useCallback(
    (paramName: string): string[] => {
      const searchParams = new URLSearchParams(location.search);
      const param = searchParams.get(paramName);
      return param ? decodeURIComponent(param).split(",") : [];
    },
    [location.search],
  );

  const getParamAsString = useCallback(
    (paramName: string): string => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get(paramName) || "";
    },
    [location.search],
  );

  const getParamAsDateRange = useCallback((): DateRange | undefined => {
    const searchParams = new URLSearchParams(location.search);
    const fromDateStr = searchParams.get("fromDate");
    const toDateStr = searchParams.get("toDate");

    if (!fromDateStr && !toDateStr) return undefined;

    return {
      from: fromDateStr ? new Date(fromDateStr) : undefined,
      to: toDateStr ? new Date(toDateStr) : undefined,
    };
  }, [location.search]);

  return {
    debouncedUpdateSearch,
    updateAutocompleteParam,
    updateDateRangeParams,
    getParamAsArray,
    getParamAsString,
    getParamAsDateRange,
  };
}
