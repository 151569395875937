import {
  BaseJsonSchema,
  createArraySchemaByItemType,
  createSchemaByBasicTypes,
} from "../../helpers";
import { AppnexusBidSchema } from "./Bidders/AppnexusBidSchema";
import { CriteoBidSchema } from "./Bidders/CriteoBidSchema";
import { IxBidSchema } from "./Bidders/IxBidSchema";
import { MedianetBidSchema } from "./Bidders/MedianetBidSchema";
import { PubmaticBidSchema } from "./Bidders/PubmaticBidSchema";
import { RubiconBidSchema } from "./Bidders/RubiconBidSchema";
import { SharethroughBidSchema } from "./Bidders/SharethroughBidSchema";
import { TripleliftBidSchema } from "./Bidders/TripleliftBidSchema";
import { KargoBidSchema } from "./Bidders/KargoBidSchema";

const IRollConfig: BaseJsonSchema = {
  type: "object",
  properties: {
    repositoryId: createSchemaByBasicTypes("string"),
    prebidbids: createArraySchemaByItemType(undefined, [
      KargoBidSchema,
      PubmaticBidSchema,
      RubiconBidSchema,
      AppnexusBidSchema,
      IxBidSchema,
      TripleliftBidSchema,
      CriteoBidSchema,
      SharethroughBidSchema,
      MedianetBidSchema,
    ]),
    prebiddfpad: {
      type: "object",
      properties: {
        cust_params: createArraySchemaByItemType("string"),
        ui: createSchemaByBasicTypes("string"),
      },
    },
    htlkey: createSchemaByBasicTypes("string"),
    longitude: {
      type: "object",
      description:
        "LongitudeAds configuration. Defining this overrides all other auction types (htl/prebid/tam)",
      properties: {
        unitId: createSchemaByBasicTypes("string", "videotest"),
      },
    },
    adtagurl: createSchemaByBasicTypes("string"),
  },
  additionalProperties: {
    type: ["number", "string", "boolean", "array", "object", "null"],
    description:
      "no type declaration on this property, you are adding a new one",
  },
};

export default IRollConfig;
