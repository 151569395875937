import {
  BaseJsonSchema,
  createArraySchemaByItemType,
  createSchemaByBasicTypes,
} from "../../helpers";
import { AppnexusBidSchema } from "../pre-mid-pos-rollConfig/Bidders/AppnexusBidSchema";
import { PubmaticBidSchema } from "../pre-mid-pos-rollConfig/Bidders/PubmaticBidSchema";
import { RubiconBidSchema } from "../pre-mid-pos-rollConfig/Bidders/RubiconBidSchema";
import { MedianetBidSchema } from "../pre-mid-pos-rollConfig/Bidders/MedianetBidSchema";
import { SharethroughBidSchema } from "../pre-mid-pos-rollConfig/Bidders/SharethroughBidSchema";
import { KargoBidSchema } from "../pre-mid-pos-rollConfig/Bidders/KargoBidSchema";
import { TripleliftBidSchema } from "../pre-mid-pos-rollConfig/Bidders/TripleliftBidSchema";
import { SmartAdServerBidSchema } from "../pre-mid-pos-rollConfig/Bidders/SmartAdServerBidSchema";
import { CriteoBidSchema } from "../pre-mid-pos-rollConfig/Bidders/CriteoBidSchema";
import { IxBidSchema } from "../pre-mid-pos-rollConfig/Bidders/IxBidSchema";

export const IPrebid: BaseJsonSchema = {
  type: "object",
  properties: {
    nonUniversalBidders: createArraySchemaByItemType(undefined, [
      AppnexusBidSchema,
      CriteoBidSchema,
      IxBidSchema,
      PubmaticBidSchema,
      RubiconBidSchema,
      MedianetBidSchema,
      SharethroughBidSchema,
      KargoBidSchema,
      TripleliftBidSchema,
      SmartAdServerBidSchema,
    ]),
    disableBidders: {
      description: "disableBidders is deprecated, use `enableBidders` instead",
      deprecated: true,
      type: "object",
      properties: {
        appnexus: createSchemaByBasicTypes("boolean"),
        gumgum: createSchemaByBasicTypes("boolean"),
        criteo: createSchemaByBasicTypes("boolean"),
        ix: createSchemaByBasicTypes("boolean"),
        medianet: createSchemaByBasicTypes("boolean"),
        openx: createSchemaByBasicTypes("boolean"),
        pubmatic: createSchemaByBasicTypes("boolean"),
        rubicon: createSchemaByBasicTypes("boolean"),
        sovrn: createSchemaByBasicTypes("boolean"),
        sharethrough: createSchemaByBasicTypes("boolean"),
        triplelift: createSchemaByBasicTypes("boolean"),
      },
      required: [
        "appnexus",
        "gumgum",
        "criteo",
        "ix",
        "medianet",
        "openx",
        "pubmatic",
        "rubicon",
        "sovrn",
        "sharethrough",
        "triplelift",
      ],
    },
    enabledBidders: {
      type: "object",
      properties: {
        appnexus: createSchemaByBasicTypes("boolean"),
        gumgum: createSchemaByBasicTypes("boolean"),
        criteo: createSchemaByBasicTypes("boolean"),
        ix: createSchemaByBasicTypes("boolean"),
        medianet: createSchemaByBasicTypes("boolean"),
        openx: createSchemaByBasicTypes("boolean"),
        pubmatic: createSchemaByBasicTypes("boolean"),
        rubicon: createSchemaByBasicTypes("boolean"),
        sovrn: createSchemaByBasicTypes("boolean"),
        sharethrough: createSchemaByBasicTypes("boolean"),
        triplelift: createSchemaByBasicTypes("boolean"),
      },
      required: [
        "appnexus",
        "gumgum",
        "criteo",
        "ix",
        "medianet",
        "openx",
        "pubmatic",
        "rubicon",
        "sovrn",
        "sharethrough",
        "triplelift",
      ],
    },
  },
  additionalProperties: false,
};
