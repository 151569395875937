import React from "react";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import { Box, Card, Grid, Typography } from "@mui/material";
import KrgDataTable from "@kargo/shared-components.krg-data-table";

import usePlaylist from "../../../hooks/usePlaylist";
import LoadingCircle from "../../../components/LoadingCircle";
import { useStyles } from "../../../components/VideosTable/VideosTable";
import {
  PlayableStream,
  PlaylistCreationEnum,
  PlaylistCreationType,
  PlaylistSingleMediaRow,
  PlaylistStreamableMedia,
} from "../../../@types/playlist.types";
import { formatDuration } from "../../../helpers/videos";
import ThumbnailPlaceholder from "../../../components/ThumbnailPlaceholder";

const SinglePlaylistMediaList = ({
  token,
  creationType,
}: {
  token: string;
  creationType: PlaylistCreationType;
}) => {
  const classes = useStyles();
  const { medias } = usePlaylist(token);

  const onDeleteActionClick = (mediaToken: string) => {
    // TODO: Implement delete media from playlist
    console.log(`Implement delete media with token: ${mediaToken}`);
  };

  if (!medias)
    return (
      <Box>
        <LoadingCircle />
      </Box>
    );

  const additionalDataColumns = [];
  if (creationType === PlaylistCreationEnum.NONE) {
    additionalDataColumns.push({
      headerName: "Actions",
      field: "actions",
      width: "50px",
      renderCell: (row: PlaylistSingleMediaRow) => (
        <Delete
          sx={{ cursor: "pointer" }}
          onClick={() => onDeleteActionClick(row?.media_token)}
        />
      ),
    });
  }

  return (
    <Box>
      <Card sx={{ borderColor: "#000" }}>
        {/* Data Table */}
        <KrgDataTable
          theme={KrgDataTable.THEME_ENUM.v2}
          className={classes.table}
          config={{
            pagination: {
              initialPageSize: 10,
            },
            data: {
              columns: [
                {
                  headerName: "Thumbnail",
                  type: KrgDataTable.CELL_TYPE_ENUM.thumbnail,
                  field: "thumbnail",
                  renderCell: (row: PlaylistSingleMediaRow) =>
                    !row.thumbnail ? (
                      <ThumbnailPlaceholder />
                    ) : (
                      <img
                        src={row.thumbnail}
                        alt={row.title}
                        style={{ width: "70px", height: "40px" }}
                      />
                    ),
                  width: "100px",
                },
                {
                  headerName: "Video Title",
                  // type: KrgDataTable.CELL_TYPE_ENUM.subtitle,
                  field: "title",
                  width: "200px",
                },
                {
                  headerName: "Date Added",
                  field: "dateAdded",
                  width: "100px",
                },
                {
                  headerName: "Duration",
                  field: "duration",
                  width: "100px",
                },
                ...additionalDataColumns,
              ],
              rows: medias.map(
                (
                  media: PlaylistStreamableMedia,
                  index: number,
                ): PlaylistSingleMediaRow => {
                  const defaultStream: PlayableStream =
                    media.playable_streams.find((m) => m.type === "default") ||
                    media.playable_streams[0];
                  return (
                    defaultStream && {
                      id: index, // if we have an id from media, no need to use index
                      ...media,
                      thumbnail: defaultStream?.image_url,
                      title: media.media_info.title || "",
                      dateAdded: dayjs(media.media_info?.updated_at).format(
                        "MM/DD/YYYY",
                      ),
                      duration: formatDuration(
                        defaultStream?.media_info?.duration || 0,
                      ),
                    }
                  );
                },
              ),
            },
          }}
        />
      </Card>
      <Grid container spacing={2} sx={{ marginTop: "1px" }}>
        <Grid item xs={6} md={4} xl={3}>
          <Typography sx={{ fontSize: "11px" }}>Total Duration:</Typography>
          <Typography sx={{ fontSize: "16px" }}>
            {formatDuration(
              medias.reduce(
                (acc: number, media: PlaylistStreamableMedia) =>
                  acc + (media.playable_streams[0]?.media_info?.duration || 0),
                0,
              ),
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} xl={3}>
          <Typography sx={{ fontSize: "11px" }}>No of Videos:</Typography>
          <Typography sx={{ fontSize: "16px" }}>{medias.length}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SinglePlaylistMediaList;
