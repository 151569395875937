import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Events, COLOR_ENUM } from '@kargo/shared-components.krg-shared';
import { ToggleButtonItem } from './shared/interface';
import { SIZE_ENUM, ORIENTATION_ENUM, COLOR_ENUM_STANDARD } from './shared/enums';


type Props = Events & {
  /**
   * @summary
   * Toggle button group class name
   */
  buttonGroupClassName?: string,
  /**
   * @summary
   * Button class name
   */
  buttonClassName?: string,
  /**
   * @summary
   * Toggle buttons list
   */
  buttons: ToggleButtonItem[],
  /**
   * @summary
   * button color
   * @default
   * COLOR_ENUM.standard
   */
  color?: COLOR_ENUM_STANDARD | COLOR_ENUM,
  /**
   * @summary
   * Button size
   * @default
   * SIZE_ENUM.medium
   */
  size?: SIZE_ENUM,
  /**
   * @summary
   * Selected toggle button value
   */
  selectedButtons?: string[],
  /**
   * @summary
   * Toggle buttons orientation
   * @default
   * ORIENTATION_ENUM.horizontal
   */
  orientation?: ORIENTATION_ENUM,
  /**
   * @summary
   * Ability to toggle more than one button
   * @default
   * true
   */
  isSingleSelection?: boolean,
  /**
   * @summary
   * If `false`, the button will be disabled
   * @default
   * "true"
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Callback fired when selected button is changed
   */
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void,
};

const KrgToggleButton = (
  {
    buttonGroupClassName = '',
    buttonClassName = '',
    buttons,
    size = SIZE_ENUM.medium,
    color = COLOR_ENUM_STANDARD.standard,
    selectedButtons = [],
    orientation = ORIENTATION_ENUM.horizontal,
    isSingleSelection = true,
    isEnabled = true,
    onChange,
    onFocus,
    onBlur,
  }: Props) => {

  return (
    <ToggleButtonGroup
      className={buttonGroupClassName}
      orientation={orientation}
      color={color}
      size={size}
      value={selectedButtons}
      exclusive={isSingleSelection}
      disabled={!isEnabled}
      onChange={(e, value) => onChange?.(isSingleSelection ? [value] : value)}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {
        buttons.map(button => (
          <ToggleButton
            id={button.id}
            key={button.value}
            size={size}
            className={buttonClassName}
            value={button.value || ''}
            disabled={!isEnabled || button.isEnabled === false}
            sx={{
              height: size === 'large' ? '3.7334em' : 'auto',
              '& svg': {
                fontSize: '1.5rem'
              },
            }}
          >
            {button.isStartIcon && button.icon}
            {button.label && button.label}
            {!button.isStartIcon && button.icon}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
};

KrgToggleButton.ORIENTATION_ENUM = ORIENTATION_ENUM;
KrgToggleButton.SIZE_ENUM = SIZE_ENUM;
KrgToggleButton.COLOR_ENUM = COLOR_ENUM;

export default KrgToggleButton;
