export enum SIZE_ENUM {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum ORIENTATION_ENUM {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export enum COLOR_ENUM_STANDARD {
  standard = 'standard',
}
