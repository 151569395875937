import React from "react";
import { Delete, Edit, ContentCopy } from "@mui/icons-material";
import { Playlist } from "../../@types/playlist.types";
import { PlaylistActionType } from "../../@types/media.types";
import { playlistCopyEnabled } from "../../constants/constants";

interface PlaylistActionsProps {
  playlist: Playlist;
  onActionClick: (type: PlaylistActionType, playlist: Playlist) => void;
  readonly onEditActionClick?: (token: string) => void;
}

const iconStyle = {
  ml: "10px",
  "&:hover": {
    cursor: "pointer",
    color: "#03f",
  },
};

const disabledStyle = {
  color: "rgba(0, 0, 0, 0.26)",
  cursor: "not-allowed",
  pointerEvents: "none",
  ...iconStyle,
};

export default function PlaylistActions({
  playlist,
  onActionClick,
  onEditActionClick,
}: PlaylistActionsProps) {
  return (
    <>
      <Edit sx={{ ...iconStyle, ml: "0px" }} onClick={() => onEditActionClick(playlist.token)} />
      <ContentCopy
        sx={playlistCopyEnabled ? iconStyle : disabledStyle}
        onClick={() => onActionClick("copy", playlist)}
      />
      <Delete
        sx={iconStyle}
        onClick={() => onActionClick("delete", playlist)}
      />
    </>
  );
}
