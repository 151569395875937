import React, { useEffect, useRef } from "react";

import useVideos from "../hooks/useVideos";
import useSnackbar from "../hooks/useSnackbar";
import {
  MAX_ALLOWED_VIDEO_UPLOADS,
  UPDATE_PROGRESS_ON_PERCENTAGE,
} from "../constants/constants";
import {
  useUploadDispatchContext,
  useUploadStateContext,
} from "../context/upload/upload.provider";
import { UploadActionEnums } from "../@types/upload.types";

const UploadVideoFilesComponent = ({
  showUploadPopup,
  publisherGroup,
}: {
  showUploadPopup: boolean;
  publisherGroup?: string;
}) => {
  const inputFileRef = useRef(null);
  const { api } = useVideos({});
  const { snackbarSuccess, snackbarWarning } = useSnackbar();
  const uploadState = useUploadStateContext();
  const dispatch = useUploadDispatchContext();
  const { uploadingVideos, abortController } = uploadState;

  useEffect(() => {
    if (showUploadPopup === true) inputFileRef.current.click();
  }, [showUploadPopup]);

  const uploadDispatcher = (type: UploadActionEnums, payload?: any) => {
    if (type === UploadActionEnums.VIDEO_UPLOAD_PROGRESS && payload.progress) {
      if (!uploadingVideos[payload?.index]) return;
      const prevProgress = uploadingVideos[payload.index].progress;
      if (payload.progress - prevProgress >= UPDATE_PROGRESS_ON_PERCENTAGE) {
        dispatch({ type, payload });
      }
      return;
    }
    dispatch({ type, payload: payload || undefined });
  };

  const onFileSelected = React.useCallback(
    (acceptedFiles: any) => {
      const files = Array.from(acceptedFiles.target.files);
      if (files && files?.length > MAX_ALLOWED_VIDEO_UPLOADS) {
        snackbarWarning(
          `You can only upload ${MAX_ALLOWED_VIDEO_UPLOADS} video(s) at a time`,
        );
        return;
      }
      uploadDispatcher(UploadActionEnums.UPLOAD_STARTED, {
        uploadingFilesCount: files.length,
      });
      files.forEach((file: any, index) => {
        api.create(
          {
            file,
            index,
            abortController,
            uploadDispatcher,
            ...(publisherGroup && { publisherUsername: publisherGroup }),
            onCreate: ({ token }, signedURLs) =>
              uploadDispatcher(UploadActionEnums.VIDEO_SIGNED_URL_CREATED, {
                token,
                index,
                videoURL: signedURLs.url,
                imageURL: signedURLs.imageURL,
              }),
          },
          {
            onSuccess: () => {
              snackbarSuccess(`Video Added to Library`);
            },
          },
        );
      });
    },
    [publisherGroup],
  );

  return (
    <>
      <input
        type="file"
        multiple
        accept="video/*"
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={onFileSelected}
      />
    </>
  );
};

export default UploadVideoFilesComponent;
