import React, { useState, useEffect } from "react";
import { Box, Divider, Grid } from "@mui/material";
import usePlaylists from "../../hooks/usePlaylists";
import CreatePlaylistForm from "./CreatePlaylistForm";
import { GenerateFormType, Playlist } from "../../@types/playlist.types";
import SearchInput from "../../components/SearchInput";
import { DatePicker, DateRange } from "../../components/DatePicker/DatePicker";
import { AutocompleteProps } from "../../@types/media.types";
import PlaylistTypeSelect from "../../components/Select/PlaylistTypeSelect";
import MediaLoader from "./MediaLoader";
import PlaylistsTable from "../../components/PlaylistsTable/PlaylistsTable";
import useSnackbar from "../../hooks/useSnackbar";
import PlaylistGenerateModal from "../../components/Modals/PlaylistGenerateModal";
import {
  MIN_CHARACTERS_FOR_SEARCH,
  playlistTypes,
} from "../../constants/constants";
import { useURLParams } from "../../hooks/useUrlParams";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  publisherGroup?: string;
  publisherUsername?: string;
  selectedPlaylists?: string[];
  onChangeSelected: (selectedPlaylists: string[]) => void;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: (playlist: Playlist) => void;
  };
  generateFormType: GenerateFormType | undefined;
  closeGenerateForm: () => void;
};

export default function Playlists({
  asAdmin,
  publisherSlug,
  publisherGroup,
  publisherUsername,
  onChangeSelected,
  createOptions: copt,
  selectedPlaylists,
  generateFormType,
  closeGenerateForm,
}: Props) {
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState({});
  const [showPlaylist, setShowPlaylist] = useState(false);
  const { snackbarWarning } = useSnackbar();
  const [playlistType, setPlaylistType] = useState<AutocompleteProps[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const {
    debouncedUpdateSearch,
    updateAutocompleteParam,
    updateDateRangeParams,
    getParamAsArray,
    getParamAsString,
    getParamAsDateRange,
  } = useURLParams();

  const {
    playlists,
    query: { isFetching, isFetched },
  } = usePlaylists(query);

  // Handle toggling selection for all Playlists
  const handleToggleAll = (checked: boolean) => {
    if (!checked) {
      onChangeSelected([]);
      return;
    }
    if (playlists.length === 0) {
      snackbarWarning("The Playlist Library is empty.");
      return;
    }
    const selectedTokens = playlists.map(
      (playlist: Playlist) => playlist.token,
    );
    onChangeSelected(selectedTokens);
  };

  // Handle toggling selection for a single Playlist
  const handlePlaylistToggle = (playlistToken: string, isSelected: boolean) => {
    if (isSelected) {
      onChangeSelected([...selectedPlaylists, playlistToken]);
    } else {
      onChangeSelected(
        selectedPlaylists.filter((token) => token !== playlistToken),
      );
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    debouncedUpdateSearch(value);
  };

  const handleType = (types: AutocompleteProps[]) => {
    setPlaylistType(types);
    updateAutocompleteParam("playlistType", types, "name");
  };

  const handleDateChange = (range: DateRange | undefined) => {
    setDateRange(range);
    updateDateRangeParams(range);
  };

  useEffect(() => {
    const searchParam = getParamAsString("search");
    const typeParam = getParamAsArray("playlistType");
    const range: DateRange | undefined = getParamAsDateRange();
    setSearch(searchParam);
    setPlaylistType(
      playlistTypes.filter((type) => typeParam.includes(type.name)),
    );
    setDateRange(range);
  }, []);

  useEffect(() => {
    const playlistQuery = {
      ...(asAdmin && { asAdmin }),
      ...(search.length >= MIN_CHARACTERS_FOR_SEARCH && {
        title: search || "",
      }),
      ...(publisherSlug && { slug: publisherSlug }),
      ...(playlistType.length > 0 && {
        type: playlistType.map((type) => type.name?.toLowerCase()),
      }),
      ...(dateRange &&
        dateRange.to &&
        dateRange.from && { date_range: dateRange }),
    };
    setQuery(playlistQuery);
  }, [search, playlistType, dateRange, publisherSlug, asAdmin]);

  useEffect(() => {
    setShowPlaylist((asAdmin && Boolean(publisherSlug)) || !asAdmin);
  }, [asAdmin, publisherSlug]);

  return (
    <Box>
      <Grid
        container
        item
        xs={12}
        md={12}
        spacing={2}
        justifyContent="flex-start"
      >
        <Grid item xs={12} md="auto">
          <SearchInput
            placeholder="Search"
            text={search}
            onTextChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <PlaylistTypeSelect value={playlistType} onChange={handleType} />
        </Grid>
        <Grid item xs={12} md="auto">
          <DatePicker
            value={dateRange}
            onDateRangeChange={(selectedDate) => handleDateChange(selectedDate)}
          />
        </Grid>
      </Grid>
      <Divider
        variant="fullWidth"
        sx={{
          mt: "21px",
          borderColor: "transparent",
        }}
      />

      {!isFetched && isFetching ? (
        <MediaLoader />
      ) : (
        <>
          {copt?.isVisible && (
            <CreatePlaylistForm
              publisherGroup={publisherGroup}
              username={publisherUsername}
              onClose={copt.onClose}
              onSubmit={copt.onSubmit}
            />
          )}
          <PlaylistGenerateModal
            title={generateFormType}
            isOpen={!!generateFormType}
            onClose={closeGenerateForm}
          />
          {showPlaylist && (
            <PlaylistsTable
              playlists={playlists}
              selectedPlaylists={selectedPlaylists}
              onToggleAll={handleToggleAll}
              onPlaylistToggle={handlePlaylistToggle}
            />
          )}
        </>
      )}
    </Box>
  );
}
