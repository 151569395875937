import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  menuPaper: {
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: "-10px",
    minWidth: "160px",
  },
  menuItem: {
    fontSize: "11px",
    padding: "8px 16px",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  menuList: {
    padding: 0,
  },
});

type AnchorMenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  menuItems: {
    label: string;
    onClick: () => void;
  }[];
};

const AnchorMenu: React.FC<AnchorMenuProps> = ({
  anchorEl,
  open,
  onClose,
  menuItems,
}) => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.menuPaper }}
      MenuListProps={{
        "aria-labelledby": "edit-videos",
        className: classes.menuList,
      }}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.label}
          className={classes.menuItem}
          onClick={() => {
            item.onClick();
            onClose();
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default AnchorMenu;
