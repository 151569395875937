import React, { useEffect } from "react";
import MonacoEditor, { useMonaco } from "@monaco-editor/react";
import { IJsonEditor } from "./type";

export default function RegularEditor(props: IJsonEditor) {
  const {
    schemaValidation,
    onChange,
    onValidate,
    editValue,
    autoFoldBrackets,
    // Schema will be tested against both severity 4: warning, 8: error
    severity = [4, 8],
  } = props;
  const editorRef = React.useRef();
  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: "schemas.json",
            fileMatch: ["*"],
            schema: schemaValidation,
          },
        ],
      });
    }
  }, [monaco, editorRef.current, schemaValidation]);

  function handleEditorDidMount(editor) {
    if (autoFoldBrackets) {
      editor.getAction("editor.foldLevel2").run();
    }
    editorRef.current = editor;
  }

  return (
    <MonacoEditor
      height="100%"
      language="json"
      value={editValue}
      onChange={onChange}
      onMount={(editor) => handleEditorDidMount(editor)}
      onValidate={(values) => {
        onValidate(values.filter((v) => severity.includes(v.severity)));
      }}
      options={{
        foldingStrategy: "indentation",
      }}
    />
  );
}
