import {
  createArraySchemaByItemType,
  createSchemaByBasicTypes,
} from "../../helpers";

export const IFvpOutstreamOptions = createArraySchemaByItemType(undefined, [
  {
    type: "object",
    properties: {
      type: createSchemaByBasicTypes("string", "outstream"),
      width: createSchemaByBasicTypes("number"),
      height: createSchemaByBasicTypes("number"),
      placement: {
        type: "object",
        properties: {
          desktop: {
            type: "object",
            properties: {
              bottom: createSchemaByBasicTypes("number"),
              left: createSchemaByBasicTypes("number"),
              right: createSchemaByBasicTypes("number"),
              top: createSchemaByBasicTypes("number"),
            },
          },
          mobile: {
            type: "object",
            properties: {
              bottom: createSchemaByBasicTypes("number"),
              left: createSchemaByBasicTypes("number"),
              right: createSchemaByBasicTypes("number"),
              top: createSchemaByBasicTypes("number"),
            },
          },
        },
      },
    },
    additionalProperties: false,
  },
]);
