import React from "react";
import { generatePath, useParams } from "react-router";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import KrgToggleButton from "@kargo/shared-components.krg-toggle-button";
import { Box } from "@mui/material";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import { useSuperAdminContext } from "../../../routes/super_admin";
import JsonEditor from "../../../components/JsonEditor";
import SectionNavBar from "../../../components/SectionNavBar";
import { usePublisherContext } from "../../../hooks/usePublisherProvider";
import { useDomains } from "../../../hooks/useDomains";
import LoadingCircle from "../../../components/LoadingCircle";
import { getPlayerSchema } from "./schemas/playerConfig";
import { BaseJsonSchema } from "./schemas/helpers";
import "./styles.scss";
import { useDomainConfig } from "../../../hooks/useDomainConfig";
import { ISuperAdminRouteParams } from "../../../routes/superadmin_types";
import { PATHS } from "../../../constants/paths";

export default function EditPage() {
  const {
    setShowEditButtons,
    editConfig,
    setEditConfig,
    setInitialData,
    setHasValidationErrors,
    slug,
    addPathToBreadCrumb,
    conflictedData,
    setConflictedData,
    isLoading,
    advancedEditMode,
    setAdvancedEditMode,
    editDomainConfig,
    setEditDomainConfig,
    setHttpMethod,
    setSelectedDomainToken,
    forceSaveSchema,
    setForceSaveSchema,
  } = useSuperAdminContext();
  const { domainToken, playerToken, revision } =
    useParams<ISuperAdminRouteParams>();
  const tabs = [{ label: "JSON Editor" }];
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [playerSchema, setPlayerSchema] = React.useState({} as BaseJsonSchema);
  const [sideByside, setSideBySide] = React.useState(false);
  const [selectedDomain, setSelectedDomain] = React.useState("");
  // TODO: Remove prettier-ignore once we copy the `.prettierrc` file from `fvp-ad-manager`
  // prettier-ignore
  const [selectedSchemaVersion, setSelectedSchemaVersion] = React.useState<string[]>(["3.0"]);

  const { publisherSlug } = usePublisherContext(true);
  const query = { slug: publisherSlug || slug };
  const { domains } = useDomains({ enabled: true, query });
  const { getRevisions } = useDomainConfig(playerToken, domainToken || "");

  React.useEffect(() => {
    setShowEditButtons(true);
    if (domains) {
      const domain = domains?.filter((d) => d.token === domainToken)[0];
      const config = domain?.configs
        ?.filter((c) => c?.token === playerToken)
        .shift();
      if (revision) {
        getRevisions(playerToken, {
          onSuccess: (res) => {
            const domainConfig = res
              .filter((c) => c.revision === Number(revision))
              .shift();
            setEditConfig(domainConfig.config);
            setEditDomainConfig(domainConfig);
          },
        });
      } else {
        setEditConfig(config?.config || {});
        setInitialData(config || {});
        setEditDomainConfig({ ...config, notes: "" });
      }
      addPathToBreadCrumb([
        {
          path: generatePath(PATHS.ADMIN_DOMAIN_CONFIG, { domainToken }),
          pathName: domain?.domain,
        },
        {
          path:
            playerToken === "add-config"
              ? generatePath(PATHS.ADMIN_DOMAIN_CONFIG, { domainToken })
              : generatePath(PATHS.ADMIN_PLAYER_CONFIG, {
                  domainToken,
                  playerToken,
                }),
          pathName: config?.key,
        },
        {
          path: window.location.pathname,
          pathName:
            revision || (playerToken === "add-config" ? "new config" : "edit"),
        },
      ]);
      setSelectedDomain(domain.domain);
    }
    return () => setShowEditButtons(false);
  }, [domains, revision]);

  React.useEffect(() => {
    setPlayerSchema(
      getPlayerSchema(
        selectedDomain,
        advancedEditMode,
        selectedSchemaVersion[0],
      ),
    );
  }, [advancedEditMode, selectedDomain, selectedSchemaVersion]);

  React.useEffect(() => {
    if (playerToken === "add-config") {
      // Sets default schema version when new config is being created
      setEditConfig({
        schemaVersion: selectedSchemaVersion[0],
      });
      setHttpMethod("create");
      setSelectedDomainToken(domainToken || "");
    }
  }, [playerToken]);

  const handleOnChanges = (value: string) => {
    if (conflictedData) {
      setConflictedData(JSON.parse(value));
    } else if (advancedEditMode) {
      setEditDomainConfig(JSON.parse(value));
      setEditConfig(JSON.parse(value).config);
    } else {
      setEditConfig(JSON.parse(value));
      setEditDomainConfig({
        ...editDomainConfig,
        config: JSON.parse(value),
      });
    }
  };

  const configJson = editConfig ? JSON.stringify(editConfig, null, 2) : "";
  const domainConfigJson = editDomainConfig
    ? JSON.stringify(editDomainConfig, null, 2)
    : "";
  const editJsonValue = advancedEditMode ? domainConfigJson : configJson;

  return (
    <div style={{ height: "90%", paddingTop: 10 }}>
      <SectionNavBar
        buttonsConfig={[]}
        tabsConfig={{
          onChange: (e: any, value?: any) => setSelectedTab(value),
          selectedTab,
          tabs,
        }}
      />
      <Box sx={{ display: "flex", flexFlow: "row wrap" }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "30%",
            marginLeft: 3,
          }}
        >
          <KrgTextInput
            text={editDomainConfig.key}
            name="key"
            isFullWidth
            theme={KrgTextInput.THEME_ENUM.v2}
            label="Player name"
            onTextChange={(key) => {
              setEditDomainConfig({ ...editDomainConfig, key });
            }}
            debounceTime={0}
            onDebounceEnd={(key) => {
              setEditDomainConfig({ ...editDomainConfig, key });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "30%",
            marginLeft: 3,
          }}
        >
          <KrgTextInput
            text={editDomainConfig.type}
            name="type"
            isFullWidth
            theme={KrgTextInput.THEME_ENUM.v2}
            label="Config Type"
            onTextChange={(type) => {
              setEditDomainConfig({ ...editDomainConfig, type });
            }}
            debounceTime={0}
            onDebounceEnd={(type) => {
              setEditDomainConfig({ ...editDomainConfig, type });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "30%",
            marginLeft: 3,
          }}
        >
          <KrgToggleButton
            buttons={[
              { label: "v3 Schema", value: "3.0" },
              { label: "v2 Schema", value: "2.0" },
            ]}
            size={KrgToggleButton.SIZE_ENUM.small}
            onChange={(newSelectedSchemaVersion) => {
              setSelectedSchemaVersion(newSelectedSchemaVersion);
            }}
            isSingleSelection
            selectedButtons={selectedSchemaVersion}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "90%",
            marginLeft: 3,
          }}
        >
          <KrgTextInput
            text={editDomainConfig.notes}
            name="notes"
            isFullWidth
            theme={KrgTextInput.THEME_ENUM.v2}
            label="Notes"
            onTextChange={(text) => {
              setEditDomainConfig({ ...editDomainConfig, notes: text });
            }}
            debounceTime={0}
            maxCharacter={56}
            onDebounceEnd={(text) => {
              setEditDomainConfig({ ...editDomainConfig, notes: text });
            }}
          />
        </Box>
      </Box>
      {conflictedData ? (
        <KrgCheckbox
          isChecked={sideByside}
          onToggle={setSideBySide}
          label="Side by side view"
          theme={KrgCheckbox.THEME_ENUM.v2}
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          width: "50%",
          marginLeft: 2,
        }}
      >
        <KrgCheckbox
          isChecked={advancedEditMode}
          onToggle={(v) => setAdvancedEditMode(v)}
          label="Advanced Edit Mode"
          theme={KrgCheckbox.THEME_ENUM.v2}
        />
        <KrgCheckbox
          isChecked={forceSaveSchema}
          onToggle={(v) => setForceSaveSchema(v)}
          label="Force Save Schema"
          theme={KrgCheckbox.THEME_ENUM.v2}
        />
      </Box>
      {domains && selectedDomain && !isLoading ? (
        <JsonEditor
          editValue={editJsonValue}
          onChange={handleOnChanges}
          onValidate={(values) => {
            setHasValidationErrors(values.length > 0);
          }}
          schemaValidation={playerSchema}
          diffMode={!!conflictedData}
          diffValue={conflictedData && JSON.stringify(conflictedData, null, 2)}
          renderSideBySide={sideByside}
        />
      ) : (
        <div className="krg-loading-shell">
          <LoadingCircle />
        </div>
      )}
    </div>
  );
}
